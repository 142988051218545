import React from 'react';
import { StyledText } from '..';

interface IProps {
	items: string[];
}

const renderListItem = (item: string) => {
	return (
		<li className={"list-item"} key={item}>
			<StyledText text={item} />
		</li>
	);
}

const List: React.FC<IProps> = ({ items }) => {
	return (
		<>
		<ul className={"list-inside list-disc text-left ml-4"} >
			{items.map(renderListItem)}
		</ul>
		</>
	);
}

export default List;