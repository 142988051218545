import { IExportState } from "./export.types";

const ExportInitialState = (): IExportState => ({
	export: {
		data: [],
		loading: false,
		error: null,
	},
	workspace: null,
	token: null,
	days: 10,
	factor: 10,
});

export default ExportInitialState;