import React, { useState } from 'react';

import { Icon, StyledText } from '..';
import { ITogglExportResponse } from '../../types';
import { MathsHelper } from '../../utils/maths';

interface IProps {
	exportData: ITogglExportResponse[];
	average: number;
}

const Balance: React.FC<IProps> = ({ exportData, average }) => {
	const [hoverActive, setHoverActive] = useState(false);
	const onBalanceHover = (isHovering: boolean) => {
		if (isHovering !== hoverActive) {
			setHoverActive(isHovering);
		}
	}

	const renderCopy = (balance: number) => {
		// const decimal = parseFloat(balance.toString().replace(',', '.'));
		// const {hours, minutes} = MathsHelper.timeConvert(decimal);

		if (balance === 0) {
			// return "There are no entries for this month. Let's get to work!";
			return "You have reached perfect balance! 🧘‍♀️";
		}

		if (balance < 0) {
			// return `You have already worked an extra ${hours.toString().replace('-', '')} hours and ${isNaN(minutes) ? 0 : minutes.toString().replace('-', '')} minutes (${balance.toString().replace('-', '')} hours) this month. Take it easy today ;)`;
			return `You have already worked an extra ${balance.toString().replace('-', '')} hours this month. Take it easy today ;)`;
		}

		if (balance > 0) {
			// return `You need to work another ${hours} hours and ${isNaN(minutes) ? 0 : minutes} minutes (${balance} hours) to break-even this month. You've got this!`;
			return `You need to work another ${balance} hours to break-even this month. You've got this!`;
		}
	}

	return (
		<div className={"absolute right-3 top-3 md:right-6 md:top-6 flex content-center"}>
			<div className={`bg-white rounded px-4 opacity-90 font-bold max-w-md ${!hoverActive ? "hidden" : ""}`}>
				<StyledText
					text={renderCopy(parseFloat(MathsHelper.calculateBalance(exportData, average)))}
				/>
			</div>
			<div className={"cursor-pointer text-purple-extralight"} onMouseOver={() => onBalanceHover(true)} onMouseOut={() => onBalanceHover(false)} >
				<Icon name={"balance"} color={"purple-extralight"} />
			</div>
		</div>
	);
}

export default Balance;