import App from "../App";
import { Export, Settings } from "../pages";
import Balance from "../pages/Balance";
import Preview from "../pages/Preview";

const pathNames = {
	settings: "/",
	export: "/export",
	playground: "/playground",
	preview: "/preview",
	balance: "/balance",
	notFound: "*",
};

const routes: INavItem[] = [
	{
		path: pathNames.settings,
		component: Settings,
	},
	{
		path: pathNames.export,
		component: Export,
	},
	{
		path: pathNames.preview,
		component: Preview,
	},
	{
		path: pathNames.balance,
		component: Balance,
	},
	{
		path: pathNames.playground,
		component: App,
	},
];

export interface INavItem {
	component?: any;
	path?: string;
}

export {
	routes,
	pathNames,
}