import React from 'react';
import copy from 'copy-to-clipboard';

interface IProps {
	project: string;
	description: string;
	time: string;
}

const ProjectSummary: React.FC<IProps> = ({ project, description, time }) => {
	return (
		<div className={"w-full flex shadow rounded my-4"}>
			<div className={"flex justify-center items-center w-2/12 lg:w-1/12 bg-purple-light text-white font-extrabold rounded-tl rounded-bl"}>
				<span
					className={"cursor-pointer"}
					onClick={() => copy(time)}
				>
					{time}
				</span>
			</div>
			<div className={"flex flex-col w-10/12 lg:w-11/12 text-left rounded-tr rounded-br p-2"}>
				<span
					className={"font-medium mb-1 cursor-pointer"}
					onClick={() => copy(project)}
				>
					{project}
				</span>
				<span
					className={"font-normal cursor-pointer whitespace-pre-line"}
					onClick={() => copy(description)}
				>
					{description}
				</span>
			</div>
		</div>
	);
}

export default ProjectSummary;