import React from 'react';

interface IProps {
	text: string;
}

const StyledText: React.FC<IProps> = ({ text }) => {
	return (
		<>
			<span className={"font-light"}>{text}</span>
		</>
	);
}

export default StyledText;