import { createStore, Reducer, combineReducers, applyMiddleware } from 'redux';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reduxThunk from 'redux-thunk';

import ExportReducer from './export/export.reducer';
import { IStoreState } from './store.types';
import CoreReducer from './core/core.reducer';

const middleware = [reduxThunk];

const rootPersistConfig = {
	storage,
	key: 'root',
};

const corePersistConfig = {
	storage,
	key: 'core',
};

const rootReducer = combineReducers<Reducer>({
	core: persistReducer(corePersistConfig, CoreReducer as never),
	toggl: ExportReducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middleware)));

export const useTypedSelector: TypedUseSelectorHook<IStoreState> = useSelector;