import { useTypedSelector } from "../../store/index";
import { selectExport, selectExportLoading, selectExportError, selectWorkspace, selectToken, selectDays, selectFactor } from './export.selectors';

const useExport = () => {
	const data = useTypedSelector((state) => selectExport(state));
	const loading = useTypedSelector((state) => selectExportLoading(state));
	const error = useTypedSelector((state) => selectExportError(state));

	return { data, loading, error };
};

const useUserData = () => {
	const workspace = useTypedSelector((state) => selectWorkspace(state));
	const token = useTypedSelector((state) => selectToken(state));
	const days = useTypedSelector((state) => selectDays(state));
	const factor = useTypedSelector((state) => selectFactor(state));

	return { workspace, token, days, factor };
}

export { useExport, useUserData };
