import { IDaySummary, IEntry, ITogglExportProjectsResponse, ITogglExportResponse } from "../types";

export class MapHelper {
	public static mapProjects(projects: ITogglExportProjectsResponse): IEntry[] {
		const projectKeys: string[] = Object.keys(projects);

		return projectKeys.map((key: string) => ({
			project: key,
			description: projects[key].description,
			time: `${projects[key].total}`,
		}));
	}

	public static mapResponse(data: ITogglExportResponse[]): IDaySummary[] {
		return data.map((d) => ({
			date: d.date,
			total: d.total,
			entries: this.mapProjects(d.projects),
		}));
	}
}