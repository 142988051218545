import React, { useState } from 'react';
import { DayInfo, ProjectSummary } from '..';
import { IDaySummary, IEntry } from '../../types';

interface IProps {
	data: IDaySummary;
}

const renderProjectSummary = (data: IEntry, index: number) => {
	return <ProjectSummary key={index} project={data.project} description={data.description} time={data.time} />
}

const DaySummary: React.FC<IProps> = ({ data }) => {
	const [isOpen, setIsOpen] = useState<boolean>(true);

	const handleToggle = (e: React.MouseEvent) => {
		setIsOpen(!isOpen);
	}

	return (
		<div className={"flex shadow rounded flex-col my-8"}>
			<DayInfo date={data.date} total={data.total} handleToggle={handleToggle} isOpen={isOpen} />
			<div className={"px-6 py-2 mt-2 mb-2 w-full"}>
				{isOpen && data.entries.map(renderProjectSummary)}
			</div>
		</div>
	);
}

export default DaySummary;