import { DateTime } from "luxon";

import { IDescriptiveTime, ITogglExportResponse } from "../types";

export class MathsHelper {
	public static calculateBalance(exportData: ITogglExportResponse[], average: number): string {
		const filteredDates = exportData.filter((e) => new Date(e.date).getTime() >= new Date(DateTime.local(DateTime.local().year, DateTime.local().month, 1).toISODate()).getTime());
		let total: number = 0;

		filteredDates.forEach((f) => {
			total += parseFloat(f.total.replace('u', ''));
		});

		const targetTotal = filteredDates.length * average;

		return (targetTotal - total).toFixed(2);
	}

	public static timeConvert(balance: number): IDescriptiveTime {
			// strip hours and decimals from input
			const hoursWorked: number = Math.floor(balance);
			const decimalMinutesWorked: number = Math.round((balance % 1) * 60) / 60;
			// multiply 60 by decimal to get fraction
			const minutesWorked = 60 * decimalMinutesWorked;
		
			return {
				hours: hoursWorked,
				minutes: minutesWorked,
			};
	}
}