import { IText } from "../../components/Paragraph";

const instructions: IText[][] = [
	[
		{ type: 'text', content: 'Copy your workspace id. You can find it when you login and go to your ' },
		{ type: 'link', content: 'reports', link: 'https://toggl.com/app/reports/summary/' },
		{ type: 'text', content: ". It's the last digit in the url." },
	],
	[
		{ type: 'text', content: 'Copy the API token from your ' },
		{ type: 'link', content: 'profile', link: 'https://toggl.com/app/profile' },
		{ type: 'text', content: ". You can find it at the bottom of the page." },
	],
	[
		{ type: 'text', content: 'Optionally enter the total days to return in the export.' },
	],
	[
		{ type: 'text', content: 'You may choose the factor with which to round off the duration of each task. The following options are available:' },
	],
	[{
		type: 'list', items: [
			"3 will round off to 20 minutes",
			"4 will round off to 15 minutes",
			"5 will round off to 12 minutes",
			"10 will round off to 6 minutes",
		]
	}],
	[
		{ type: 'text', content: 'When no factor has been defined it will default to 10.' },
	],
	[
		{ type: 'text', content: 'Fill in the form with the correct values.' },
	],
];

export default instructions