import { pathOr } from 'ramda';

import { IResponseError, ITogglExportResponse } from '../../types';
import { IStoreState } from "../store.types";

const selectExport = (state: IStoreState): ITogglExportResponse[] | null =>
	pathOr(null, ['toggl', 'export', 'data'], state);

const selectExportLoading = (state: IStoreState): boolean =>
	pathOr(false, ['toggl', 'export', 'loading'], state);

const selectExportError = (state: IStoreState): IResponseError | null =>
	pathOr(null, ['toggl', 'export', 'error'], state);

const selectWorkspace = (state: IStoreState): string | null =>
	pathOr(null, ['toggl', 'workspace'], state);

const selectToken = (state: IStoreState): string | null =>
	pathOr(null, ['toggl', 'token'], state);

const selectDays = (state: IStoreState): number =>
	pathOr(10, ['toggl', 'days'], state);

const selectFactor = (state: IStoreState): number =>
	pathOr(10, ['toggl', 'factor'], state);

export {
	selectExport,
	selectExportLoading,
	selectExportError,
	selectWorkspace,
	selectToken,
	selectDays,
	selectFactor,
}