import ExportInitialState from "./export.initial-state";
import { ExportActionTypes, IExportAction } from "./export.types";

const ExportReducer = (state = ExportInitialState(), action: IExportAction) => {
	switch (action.type) {
		case ExportActionTypes.FETCH_EXPORT: {
			return {
				...state,
				export: {
					...state.export,
					loading: true,
				},
			};
		}

		case ExportActionTypes.FETCH_EXPORT_SUCCESFUL: {
			return {
				...state,
				export: {
					...state.export,
					data: action.export,
					loading: false,
					error: null,
				},
			};
		}

		case ExportActionTypes.FETCH_EXPORT_ERROR: {
			return {
				...state,
				export: {
					...state.export,
					loading: false,
					error: action.err,
				}
			}
		}

		default: {
			return state;
		}
	}
};

export default ExportReducer;