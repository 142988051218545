import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { isNil } from 'ramda';
import qs from 'qs';
import { DateTime } from 'luxon';

import { Footer, StyledText } from '../../components';
import { fetchExport } from '../../store/export/export.actions';
import { useExport } from '../../store/export/export.hooks';
import { pathNames } from '../../router/routes';
import { MathsHelper } from '../../utils/maths';

const Balance: React.FC<{}> = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const { workspace, token, days, factor, average } = qs.parse(location.search, { ignoreQueryPrefix: true });
	const { data, error, loading } = useExport();

	useEffect(() => {
		if (isNil(error) && !data.length) {
			dispatch(fetchExport(workspace.toString(), token.toString(), DateTime.local().daysInMonth, parseInt(factor.toString(), 10)));
		}
	});

	if (error && !loading) {
		return (
			<Redirect to={{
				pathname: pathNames.settings,
				search: `?workspace=${workspace}&token=${token}&days=${days}&factor=${factor}`,
			}} />
		);
	}

	return (
		<div className={"appearance-none min-h-screen"}>
			<Helmet>
				<title>{"Toggl Export - Balance"}</title>
			</Helmet>

			<div className={"w-10/12 mx-auto max-w-3xl p-4 text-center text-6xl font-black"}>
				<StyledText text={MathsHelper.calculateBalance(data, average ? parseFloat(average as string) : 7.6)} />
			</div>
			<Footer />
		</div>
	);
}

export default Balance;