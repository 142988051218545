import { Action } from "redux";

import { IResponseError, ITogglExportResponse } from "../../types";

export interface IExportState {
	export : {
		data: ITogglExportResponse[];
		loading: boolean;
		error: IResponseError;
	}
	workspace: string;
	token: number;
	days: number;
	factor: number;
}

export interface IExportAction extends Action {
	export?: ITogglExportResponse[];
	error?: IResponseError;
	err?:IResponseError;
	workspace?: string;
	token?: string;
	days: number;
	factor: number;
}

export enum ExportActionTypes {
	FETCH_EXPORT = '@@export/FETCH_EXPORT',
	FETCH_EXPORT_SUCCESFUL = '@@export/FETCH_EXPORT_SUCCESFUL',
	FETCH_EXPORT_ERROR = '@@export/FETCH_EXPORT_ERROR',
}
