import { Dispatch } from 'redux';
import { AxiosError, AxiosResponse } from 'axios';

import { ExportActionTypes } from "./export.types";
import API from '../../utils/api';
import { IResponseError, ITogglExportResponse } from '../../types';

const fetchExport = (workspace: string, token: string, days: number, factor: number) => (dispatch: Dispatch) => {
	dispatch({
		type: ExportActionTypes.FETCH_EXPORT,
	});

	API
		.get(`/export?workspace=${workspace}&token=${token}&days=${days}&factor=${factor}&list=${true}`)
		.then((response: AxiosResponse<ITogglExportResponse[]>) => {
			dispatch({
				type: ExportActionTypes.FETCH_EXPORT_SUCCESFUL,
				export: response.data,
			});
		})
		.catch((error: AxiosError<IResponseError>) => {
			dispatch({
				type: ExportActionTypes.FETCH_EXPORT_ERROR,
				err: error?.response?.data,
			});
		});
};

export {
	fetchExport,
}