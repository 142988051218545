import React from 'react';
import { Helmet } from 'react-helmet';

import { DaySummary, Footer } from '../../components';
import { MapHelper } from '../../utils/map';
import preview from '../../preview';

const Preview: React.FC<{}> = () => {
	return (
		<div className={"appearance-none"}>
			<Helmet>
				<title>{"Toggl Export - Preview"}</title>
			</Helmet>

			<div className={"w-10/12 mx-auto max-w-3xl p-4"}>
				{MapHelper.mapResponse(preview).map((m, index) => <DaySummary key={index} data={m} />)}
			</div>
			<Footer />
		</div>
	);
}

export default Preview;