import { ITogglExportResponse } from "./types";

const preview: ITogglExportResponse[] = [
	{
		"date":"2021-01-03",
		"total":"6.8u",
		"projects":{
			 "Proin diam condimentum nisi":{
					"total":0.6,
					"description":"0.6u: Suscipit ac curae lorem consectetur elementum morbi. "
			 },
			 "Scelerisque himenaeos iaculis libero elementum lacinia":{
					"total":1.2,
					"description":"1u: Eleifend quis massa. \n0.3u: Pharetra convallis felis nunc fusce rutrum senectus. "
			 },
			 "Vulputate posuere hac nisl proin mi ornare suspendisse":{
					"total":0.7,
					"description":"0.7u: Luctus potenti vitae nullam nunc. "
			 },
			 "Vehicula eget quam":{
					"total":0.5,
					"description":"0.2u: Dis quis quisque taciti non. \n0.3u: Integer sodales nisl consequat malesuada rhoncus sapien neque. "
			 },
			 "Mus ultricies ultrices vivamus eros vitae":{
					"total":3.5,
					"description":"1.9u: Metus faucibus vehicula scelerisque sapien. \n0.5u: Phasellus. \n0.6u: Montes torquent. \n0.5u: Dolor magnis aenean sapien. "
			 },
			 "Sodales rhoncus praesent fusce integer":{
					"total":0.3,
					"description":"0.3u: Sapien. "
			 }
		}
 },
	{
		"date": "2021-01-02",
		"total": "9.9u",
		"projects": {
			"Lorem mattis sociis lacinia": {
				"total": 1.7,
				"description": "1u: Nibh mattis. \n0.3u: Pellentesque quisque laoreet metus interdum faucibus bibendum. \n0.4u: Scelerisque urna adipiscing"
			},
			"Feugiat dictum litora": {
				"total": 2,
				"description": "4u: Cum donec praesent habitant suscipit. \n0.3u: Fusce senectus pretium interdum sociis"
			},
			"Metus sociis suspendisse maecenas fusce lobortis felis sit": {
				"total": 2.1,
				"description": "2u: Tempor hendrerit facilisi"
			},
			"Ut torquent aliquam etiam": {
				"total": 3.6,
				"description": "0.6u: Laoreet amet fringilla tellus hac. \n0.1u: Feugiat malesuada purus neque risus lacinia "
			},
			"Augue arcu ipsum nec integer phasellus nunc magnis lectus": {
				"total": 0.5,
				"description": "0.5u: Ac pulvinar cras cursus sagittis dictum habitant. "
			}
		}
	},
	{
		"date": "2021-01-01",
		"total": "7.4u",
		"projects": {
			"Mi suscipit Aliquet sem vestibulum": {
				"total": 4.3,
				"description": "4u: Inceptos sagittis nunc cubilia facilisi in. \n0.3u: Scelerisque urna adipiscing"
			},
			"Conubia quisque suscipit rutrum pharetra ac sapien": {
				"total": 2,
				"description": "2u: Tempor hendrerit facilisi"
			},
			"Viverra accumsan natoque nibh": {
				"total": 0.6,
				"description": "0.6u: Ad auctor senectus et. \n0.1u: Feugiat malesuada purus neque risus lacinia "
			},
			"Turpis parturient arcu curabitur curae": {
				"total": 0.5,
				"description": "0.5u: Ac pulvinar cras cursus sagittis dictum habitant. "
			}
		}
	},
]

export default preview;