import qs from "qs";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";

import instructions from "../../assets/copy/instructions";
import { Button, Footer, FormItem, Paragraph, Title } from "../../components";
import { IText } from "../../components/Paragraph";
import { pathNames } from "../../router/routes";

interface IProps {}

const Settings: React.FC<IProps> = () => {
  const history = useHistory();
  const location = useLocation();
  const [workspace, setWorkspace] = useState("");
  const [token, setToken] = useState("");
  const [days, setDays] = useState(10);
  const [factor, setFactor] = useState(10);
  const [average, setAverage] = useState(7.6);
  const {
    workspace: workspaceQ,
    token: tokenQ,
    days: daysQ,
    factor: factorQ,
    average: averageQ,
  } = qs.parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    if (workspaceQ) {
      setWorkspace(workspaceQ.toString());
    }

    if (tokenQ) {
      setToken(tokenQ.toString());
    }

    if (daysQ) {
      setDays(parseInt(daysQ.toString(), 10));
    }

    if (factorQ) {
      setFactor(parseInt(factorQ.toString(), 10));
    }

    if (averageQ) {
      setAverage(parseFloat(averageQ ? averageQ.toString() : "7.6"));
    }
  }, [
    setWorkspace,
    setToken,
    setDays,
    setFactor,
    setAverage,
    workspaceQ,
    tokenQ,
    daysQ,
    factorQ,
    averageQ,
  ]);

  const handleWorkspaceChange = (e: React.FormEvent<HTMLInputElement>) => {
    setWorkspace(e.currentTarget.value);
  };

  const handleTokenChange = (e: React.FormEvent<HTMLInputElement>) => {
    setToken(e.currentTarget.value);
  };

  const handleDaysChange = (e: React.FormEvent<HTMLInputElement>) => {
    setDays(parseInt(e.currentTarget.value, 10));
  };

  const handleFactorChange = (e: React.FormEvent<HTMLInputElement>) => {
    setFactor(parseInt(e.currentTarget.value, 10));
  };

  const handleAverageChange = (e: React.FormEvent<HTMLInputElement>) => {
    setAverage(parseInt(e.currentTarget.value, 10));
  };

  const handleSubmit = (e: React.MouseEvent) => {
    if (workspace.length && token.length) {
      history.replace({
        pathname: pathNames.export,
        search: `?workspace=${workspace}&token=${token}&days=${days}&factor=${factor}&average=${average}`,
      });
    } else if (workspaceQ && tokenQ) {
      history.replace({
        pathname: pathNames.export,
        search: `?workspace=${workspaceQ.toString()}&token=${tokenQ.toString()}&days=${parseInt(
          daysQ.toString(),
          10
        )}&factor=${parseInt(factorQ.toString(), 10)}&average=${average}`,
      });
    }
  };

  return (
    <div className={"appearance-none"}>
      <Helmet>
        <title>{"Toggl Export - Settings"}</title>
      </Helmet>

      <div key={"form"} className={"w-8/12 mx-auto max-w-3xl"}>
        <div className={"shadow p-8 rounded"}>
          <Title text={"Toggl Export"} />
          <FormItem
            key={"workspace"}
            label={"Workspace ID"}
            handleInput={handleWorkspaceChange}
            value={workspace}
          />
          <FormItem
            key={"token"}
            label={"Token"}
            handleInput={handleTokenChange}
            value={token}
          />
          <FormItem
            key={"days"}
            label={"Days"}
            handleInput={handleDaysChange}
            value={days}
          />
          <FormItem
            key={"factor"}
            label={"Factor (3, 4, 5 or 10"}
            handleInput={handleFactorChange}
            value={factor}
          />
          <FormItem
            key={"average"}
            label={"Average workhours per day"}
            handleInput={handleAverageChange}
            value={average}
          />
          <Button label={"Go to export!"} handleClick={handleSubmit} />
        </div>

        <div key={"instructions"} className={"mt-16 mb-6"}>
          <Title text={"How To"} />
          {instructions.map((i: IText[], index) => (
            <Paragraph text={i} key={index} />
          ))}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Settings;
