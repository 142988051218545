import React from 'react';

interface IProps {
	type?: string;
	placeholder?: string;
	min?: number;
	max?: number;
	value?: string | number;
	handleInput: (e: React.FormEvent<HTMLInputElement>) => void;
}

const Input: React.FC<IProps> = ({ type, placeholder, min, max, value, handleInput }) => {
	return (
		<>
			<input
				type={type ? type : "text"}
				placeholder={placeholder}
				onChange={handleInput}
				className={"shadow rounded w-full h-10 text-sm p-2 border border-gray sm:border-0"}
				min={min}
				max={max}
				value={value}
			/>
		</>
	);
}

export default Input;