import React from 'react';

interface IProps {
	name: string;
	color: string
}

const Icon: React.FC<IProps> = ({ name, color }) => {
	return (
		<>
			<span className={`material-icons text-${color}`}>{name}</span>
		</>
	);
}

export default Icon;