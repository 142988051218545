import React from 'react';
import { Credits } from '..';

interface IProps {}

const Footer: React.FC<IProps> = () => {
	return (
		<div className={"text-center mt-12 mb-4"}>
			<Credits />
		</div>
	);
}

export default Footer;