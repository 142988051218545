import React from 'react';
import { Link, List, StyledText } from '..';

export interface IText {
	type: 'text' | 'link' | 'list';
	content?: string;
	link?: string;
	items?: string[];
};

interface IProps {
	text: IText[];
}

const renderParagraph = (text: IText[]) => {
	return text.map((t, index) => {
		if (t.type === 'link' && t.link && t.content) {
			return (<Link url={t.link} text={t.content} key={index} />);
		} else if (t.type === 'list' && t.items) {
			return <List items={t.items} key={index} />
		} else {
			return (<StyledText text={t.content!} key={index} />);
		}
	});
}

const Paragraph: React.FC<IProps> = ({ text }) => {
	return (
		<div key={Math.random()} className={"py-2"}>
			{renderParagraph(text)}
		</div>
	);
}

export default Paragraph;