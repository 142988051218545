import React from 'react';

interface IProps {
	label: string;
}

const Label: React.FC<IProps> = ({ label }) => {
	return (
		<>
			<span className={"font-bold text-sm my-2"}>{label}</span>
		</>
	);
}

export default Label;