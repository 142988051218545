import React from 'react';

interface IProps {
	text: string;
}

const Title: React.FC<IProps> = ({ text }) => {
	return (
		<>
			<h1 className={"font-bold text-4xl text-center mb-6"}>{text}</h1>
		</>
	);
}

export default Title;