import React from 'react';
import { Link, StyledText } from '..';

interface IProps {}

const Credits: React.FC<IProps> = () => {
	return (
		<>
			<span className={"text-xs block font-thin"}>
				<StyledText text={"Powered by "} />
				<Link url={"https://toggl.thms.be/"} text={"toggl.thms.be"} />
			</span>
		</>
	);
}

export default Credits;