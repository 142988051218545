import CoreInitialState from "./core.initial-state";
import { ICoreAction } from "./core.types";

const CoreReducer = (state = CoreInitialState(), action: ICoreAction) => {
	switch (action.type) {
		default: {
			return state;
		}
	}
};

export default CoreReducer;
