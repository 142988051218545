import React from 'react';
import { Input, Label } from '..';

interface IProps {
	label: string;
	value?: string | number;
	handleInput: (e: React.FormEvent<HTMLInputElement>) => void;
}

const FormItem: React.FC<IProps> = ({ label, value, handleInput }) => {
	return (
		<div className={"my-4 text-left flex flex-col"}>
			<Label label={label} />
			<Input handleInput={handleInput} value={value} />
		</div>
	);
}

export default FormItem;