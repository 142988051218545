import { ITogglExportResponse } from "./types";

const mock: ITogglExportResponse[] = [
	{
		 "date":"2021-09-03",
		 "total":"7.8u",
		 "projects":{
				"SHD - LMR (Bebat) Fostplus RCL Q3 Contact FP":{
					 "total":4.7,
					 "description":"4u: RP-484 Contactformulier uitbreiden. 0.7u: RP-483 Verwijderen contactformulier onder elk FAQ item. "
				},
				"SHD - academy NB":{
					 "total":2,
					 "description":"2u: Workshops voorbereiding. "
				},
				"SHD - Roularta (Dev) ONE APP Poc's FP":{
					 "total":0.6,
					 "description":"0.6u: Overleg iFrames Roularta app. 0.1u: Roularta daily. "
				},
				"SHD - LMR (Bebat) Fostplus Recycle app 21 Q3 FP":{
					 "total":0.5,
					 "description":"0.5u: PR's nakijken (firebase). "
				}
		 }
	},
	{
		 "date":"2021-09-02",
		 "total":"7.3u",
		 "projects":{
				"SHD - LMR (Bebat) Fostplus RCL Q3 Contact FP":{
					 "total":5.6,
					 "description":"3.7u: RP-484 Contactformulier uitbreiden. 1.9u: RP-476 Contactformulier knop toevoegen aan het menu. "
				},
				"SHD-CRP(Digipolis)-CPSNV18/2224-Visit Supp 2018+":{
					 "total":0.2,
					 "description":"0.2u: 555-artikel-stroomt-niet-door. "
				},
				"SHD - D01 (Digipolis) Form support FP":{
					 "total":0.2,
					 "description":"0.2u: MTA ACPFSE-755. "
				},
				"SHD - Roularta (Dev) ONE APP Poc's FP":{
					 "total":0.2,
					 "description":"0.2u: Roularta daily. "
				},
				"SHD - LMR (Bebat) Fostplus RCL Q3 Languages FP":{
					 "total":0.4,
					 "description":"0.4u: PR's nakijken. "
				},
				"SHD - LF (Bebat/Fostplus) recycle app sup prio4 FP":{
					 "total":0.3,
					 "description":"0.3u: RP-435 RPSD - Update download links in iFrame. "
				},
				"SHD - LMR (Bebat) Fostplus Recycle app 21 Q3 FP":{
					 "total":0.4,
					 "description":"0.4u: RP-566 Image communicatiebericht verdwijnt. "
				}
		 }
	},
	{
		 "date":"2021-09-01",
		 "total":"7.9u",
		 "projects":{
				"SHD - LF (Bebat/Fostplus) recycle app sup prio4 FP":{
					 "total":0.1,
					 "description":"0.1u: Verwijderen noodbericht `Recyclagepark op afspraak` Ibogem. "
				},
				"SHD - D01 (Digipolis) Form support FP":{
					 "total":0.7,
					 "description":"0.2u: MTA ACPFSE-755. 0.5u: ACPFSE-755 Toevoegen publishedBy veld. "
				},
				"SHD-CRP(Digipolis)-CPSNV18/2224-Visit Supp 2018+":{
					 "total":0.3,
					 "description":"0.3u: 555-artikel-stroomt-niet-door. "
				},
				"SHD - LMR (Bebat) Fostplus RCL Q3 Languages FP":{
					 "total":6,
					 "description":"5.4u: RP-560 Fallback oudere app versie bij ontbrekende talen. 0.4u: PR's nakijken. 0.2u: Recycle daily. "
				},
				"SHD - Roularta (Dev) ONE APP Poc's FP":{
					 "total":0.8,
					 "description":"0.2u: Roularta daily. 0.6u: PR's nakijken. "
				}
		 }
	},
	{
		 "date":"2021-08-31",
		 "total":"8.7u",
		 "projects":{
				"SHD - D01 (Digipolis) Form support FP":{
					 "total":0.5,
					 "description":"0.5u: MTA ACPFSE-755. "
				},
				"SHD - LF (Bebat) Fostplus RCL Q1 Commu FP":{
					 "total":0.6,
					 "description":"0.2u: RP-563 Carousel wordt telkens opnieuw opgebouwd bij openen app. 0.5u: RP-562 Berichten komen niet meteen in het overzicht te staan. "
				},
				"SHD - LMR (Bebat) Fostplus RCL Q3 Languages FP":{
					 "total":2.9,
					 "description":"2.7u: RP-560 Fallback oudere app versie bij ontbrekende talen. 0.2u: Recycle daily. "
				},
				"SHD - Roularta (Dev) ONE APP Poc's FP":{
					 "total":3.4,
					 "description":"0.3u: Iframe infogram. 0.6u: ROA-2075 BE: filtering issues call. 2u: Overzicht images. 0.2u: Roularta daily. 0.2u: PR's nakijken. "
				},
				"SHD - LMR (Bebat) Fostplus RCL 21 Q3 Analyses FP":{
					 "total":0.2,
					 "description":"0.2u: Inschatting update uitzonderingen. "
				},
				"SHD - academy NB":{
					 "total":0.2,
					 "description":"0.2u: Workshops voorbereiding. "
				},
				"SHD - intern":{
					 "total":0.2,
					 "description":"0.2u: Kepler weekly. "
				},
				"SHD - LMR (Bebat) Fostplus Recycle app 21 Q3 FP":{
					 "total":0.7,
					 "description":"0.7u: Deploys FE nieuwe vertalingen. "
				}
		 }
	},
	{
		 "date":"2021-08-30",
		 "total":"8.2u",
		 "projects":{
				"SHD - Roularta (Dev) ONE APP Poc's FP":{
					 "total":4.2,
					 "description":"0.9u: Tijdelijke verrijking van icons en logos + cover urls. 1.2u: Overleg bugs. 1.5u: ROA-2073 Coverbeelden KW en DZ komen worden niet correct getoond in de kiosk. 0.5u: Ontbrekende data. 0.1u: Roularta daily. "
				},
				"SHD - LMR (Bebat) Fostplus Recycle app 21 Q3 FP":{
					 "total":1.1,
					 "description":"1.1u: Sprint Retrospective Recycle Q3. "
				},
				"SHD - LMR (Bebat) Fostplus RCL Q3 Languages FP":{
					 "total":1.6,
					 "description":"1.3u: RP-560 Fallback oudere app versie bij ontbrekende talen. 0.2u: PR's nakijken. "
				},
				"SHD - LMR (Bebat) Fostplus RCL Q3 Contact FP":{
					 "total":0.8,
					 "description":"0.3u: Recycle daily. 0.6u: RP-481 Contactformulier toevoegen aan het menu zonder onboarding. "
				},
				"SHD - LF (Bebat/Fostplus) recycle app sup prio4 FP":{
					 "total":0.5,
					 "description":"0.5u: RP-435 RPSD - Update download links in iFrame. "
				}
		 }
	},
	{
		 "date":"2021-08-27",
		 "total":"6.8u",
		 "projects":{
				"SHD - LF (Bebat) Fostplus RCL Q1 Commu FP":{
					 "total":0.6,
					 "description":"0.6u: Deploy app i601a180. "
				},
				"SHD - LMR (Bebat) Fostplus Recycle app 21 Q3 FP":{
					 "total":1.2,
					 "description":"1u: Sprint Planning Recycle Q3. 0.3u: Tickets nakijken sprintplanning. "
				},
				"SHD - academy NB":{
					 "total":0.7,
					 "description":"0.7u: Workshops voorbereiding. "
				},
				"SHD - Roularta (Dev) ONE APP Poc's FP":{
					 "total":0.5,
					 "description":"0.2u: Feedback app. 0.3u: Roularta daily. "
				},
				"SHD - LMR (Bebat) Fostplus RCL Q3 Contact FP":{
					 "total":3.5,
					 "description":"1.9u: RP-486 Salesforce tags niet meer verplicht maken op FAQ vragen. 0.5u: Recycle daily. 0.6u: PR's bijwerken. 0.5u: RP-477 Contactformulier uitbreiden. "
				},
				"SHD - intern":{
					 "total":0.3,
					 "description":"0.3u: Gesprek. "
				}
		 }
	},
	{
		 "date":"2021-08-26",
		 "total":"7.9u",
		 "projects":{
				"SHD - academy NB":{
					 "total":1.2,
					 "description":"1.2u: Workshops voorbereiding. "
				},
				"SHD - LMR (Bebat) Fostplus Recycle app 21 Q3 FP":{
					 "total":0.3,
					 "description":"0.3u: Tickets nakijken sprintplanning. "
				},
				"SHD - Roularta (Dev) ONE APP Poc's FP":{
					 "total":1.4,
					 "description":"1.2u: Update public key. 0.2u: Roularta daily. "
				},
				"SHD - LF (Bebat/Fostplus) recycle app sup prio4 FP":{
					 "total":0.8,
					 "description":"0.8u: RP-558 RPSD - Begijnhofstraat in Lokeren. "
				},
				"SHD-CRP(Digipolis)-CPSNV18/2224-Visit Supp 2018+":{
					 "total":0.3,
					 "description":"0.3u: Aanmelden op staging lukt niet. "
				},
				"SHD - LMR (Bebat) Fostplus RCL Q3 Languages FP":{
					 "total":3.2,
					 "description":"3u: RP-540 Communicatie mechanisme fallback tonen. 0.2u: Recycle daily. "
				},
				"SHD - LF (Bebat) Fostplus RCL Q1 Commu FP":{
					 "total":0.7,
					 "description":"0.7u: RP-493 Lay-out blokken in carrousel. "
				}
		 }
	},
	{
		 "date":"2021-08-25",
		 "total":"4.4u",
		 "projects":{
				"SHD - LF (Bebat) Fostplus RCL Q1 Commu FP":{
					 "total":0.2,
					 "description":"0.2u: RP-493 Lay-out blokken in carrousel. "
				},
				"SHD - LMR (Bebat) Fostplus Recycle app 21 Q3 FP":{
					 "total":0.1,
					 "description":"0.1u: Deploy flow uitschrijven. "
				},
				"SHD - LMR (Bebat) Fostplus RCL Q3 Contact FP":{
					 "total":1.7,
					 "description":"1.5u: RP-476 Contactformulier knop toevoegen aan het menu. 0.2u: Recycle daily. "
				},
				"SHD - Roularta (Dev) ONE APP Poc's FP":{
					 "total":2,
					 "description":"1.4u: ROA-2067 Afbeelding als link in Libelle-artikel wordt niet getoond. 0.3u: Roularta daily. 0.3u: PR's nakijken. "
				},
				"SHD - intern":{
					 "total":0.4,
					 "description":"0.4u: Planning testing workshops. "
				}
		 }
	}
]

export default mock;